define("discourse/plugins/discourse-gamification/discourse/controllers/admin-plugins-show-discourse-gamification-leaderboards-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-gamification/discourse/components/modal/recalculate-scores-form"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _I18n, _recalculateScoresForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.leaderboards.@each.updatedAt"), (_obj = {
    modal: (0, _service.inject)(),
    dialog: (0, _service.inject)(),
    toasts: (0, _service.inject)(),
    creatingNew: false,
    sortedLeaderboards(leaderboards) {
      return leaderboards?.sortBy("updatedAt").reverse() || [];
    },
    resetNewLeaderboard() {
      this.set("creatingNew", false);
    },
    destroyLeaderboard(leaderboard) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("gamification.leaderboard.confirm_destroy"),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${leaderboard.id}`, {
            type: "DELETE"
          }).then(() => {
            this.toasts.success({
              duration: 3000,
              data: {
                message: _I18n.default.t("gamification.leaderboard.delete_success")
              }
            });
            this.model.leaderboards.removeObject(leaderboard);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    recalculateScores() {
      this.modal.show(_recalculateScoresForm.default, {
        model: this.model
      });
    },
    parseDate(date) {
      if (date) {
        // using the format YYYY-MM-DD returns the previous day for some timezones
        return date.replace(/-/g, "/");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "sortedLeaderboards", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortedLeaderboards"), _obj), _applyDecoratedDescriptor(_obj, "resetNewLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resetNewLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "destroyLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "recalculateScores", [_object.action], Object.getOwnPropertyDescriptor(_obj, "recalculateScores"), _obj)), _obj)));
});